
.gap {
  max-width: 100%;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 20px;
  box-sizing: border-box;
}
.selectbox {
  position: relative;
  left: 2.5px;
  display: inline-block;
  width: calc(100% - 5px);
  box-sizing: border-box;
}

.selectbox:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 9px;
  width: 0;
  height: 0;
  margin-top: -2.5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--theme-primary-color);
}

.selectbox select {
  padding: 4px 0;
  margin: 4px 0;
  width: 100%;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 14px;
  line-height: 16px;
  text-indent: 9px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &[disabled] {
    background-color: #f6f6f6;
    color: #000;
  }
}

.selectbox select:focus {
  border-color: var(--theme-primary-color);
  animation: shadow 0.1s ease-in-out forwards;
}
.selectbox select[readonly] {
  // background-color: #eee;
  background-color: rgba(239, 239, 239, 0.3);
  pointer-events: none;
}
.selectbox:has(select[readonly]):before{
    border-top: 5px solid #aaa;
}
@keyframes shadow {
  to {
    box-shadow: 0 0 6px var(--theme-primary-color);
  }
}
select option[value=""][disabled] {
  display: none;
}
