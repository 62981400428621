
.gap {
  max-width: 100%;
  position: relative;
  display: inline-flex;
  width: 100%;
  min-width: 20px;
  box-sizing: border-box;
}
.wrap-input {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: calc(100% - 5px);
  box-sizing: border-box;
}
input {
  box-sizing: border-box;
  font-size: 14px;
  line-height: 16px;
  text-indent: 8px;
  width: 100%;
  height: auto;
  font-family: inherit;
  padding: 4px 2px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 4px 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &.plaintext {
    padding: 4px 0;
    border-color: transparent;
    &:disabled {
      background-color: transparent;
    }
  }
}
.toggle {
    background-color: transparent;
    border: 0;
    cursor: pointer;
}
input[type=number] {
  text-align: right;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
   opacity: 1;
}
input[type=search] {
  /* width: calc(100% - 4px); */
}
input:focus {
  border-color: var(--theme-primary-color);
  animation: shadow 0.1s ease-in-out forwards;
}
@keyframes shadow {
  to {
    box-shadow: 0 0 6px var(--theme-primary-color);
    /* box-shadow: 0 0 6px red; */
  }
}
.hide-caret {
  caret-color: transparent !important;
}
