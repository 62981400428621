
/* 전역설정 */
@import 'perfect-scrollbar/css/perfect-scrollbar.css';
@import 'vue-multiselect/dist/vue-multiselect.min.css';
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic+Coding&display=swap');
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable-dynamic-subset.min.css");
:root {
  --theme-primary-color: #3D5AFE;
  --theme-secondary-color: #36B6B0;
  --theme-tertiary-color: #2956db;
  // --theme-primary-color: crimson;
}
.toggle-nav {
  background: #bbbbbb44;
  z-index: 999;
  border-radius: 10px;
  margin: 6px 7.5px;
  position: sticky;
  height: 40px;
  width: 40px;
  border: 0;
  .icon {
    color:#aaa;
    background-color: transparent;
    font-size: 30px;
  }
}
html, body{
  font-family: NanumSquareAc;
  height: 100%;
  margin: 0;
  padding: 0;
  color: #333;
}
.loading {
  // animation: loading 1s infinite;
  opacity: 0.3;
  background-color: rgb(0, 0, 0);
  width: 100%;
  height: 100%;
  // height : 2200px;
  // -webkit-transform: translateY(100%);
  // transform: translateY(100%);
  z-index: 1000;
  position:fixed;
}
.fullscreen {
  width: 100%;
}
.normal {
  display: flex;
  min-height: 100%;
  align-content: stretch;
  width: 100%;
}

.wrap .main label {
  height: 34px;
  line-height: 34px;
  display: inline-block;
}
.right{
  float:right !important;
}
.container {
  // overflow: auto;
  // width:100%;
  // background-color: red;
  padding: 0 36px;
  display: flex;
  flex-direction: column;
  font-size:14px;
  font-weight: 600;
  a {
    color: var(--theme-primary-color);
    font-weight: 600;
  }
  a:hover {
    text-decoration: underline;
  }
  .filter, .counter {
    padding: 10px 0;
    // background-color: red;
    box-sizing: border-box;
    // padding: 0 4px;
    height: fit-content;
    line-height: 40px;
    flex-basis: 0;
    flex-shrink: 0;
    border-bottom: 1px solid #ddd;
    .form-block {
      display: block;
      max-width: 100%;
      margin: 0;
    }
    .form-inline {
      display: inline-block;
      max-width: 100%;
      margin: 0;
    }
    label{
      line-height: 40px;
      box-sizing: border-box;
      margin: 0;
      min-width: 120px;
      padding: 0 10px;
      font-weight: 600;
      vertical-align: top;
      sup {
      line-height: normal;
      }
    }
    .inputbox+label {
      vertical-align: baseline;
      display: inline-block !important;
    }
    .inputbox>label {
      min-width: auto;
    }

  }
  .counter {
    border-bottom: none;
  }
  >.content {
    overflow: auto;
    background:
      linear-gradient(to right, #ffffff 33%, rgba(255,255,255, 0)),
      linear-gradient(to right, rgba(255,255,255, 0), #ffffff 66%),
      radial-gradient(farthest-side at left,  rgba(54,54,54, 0.2), rgba(0,0,0,0)),
      radial-gradient(farthest-side at right, rgba(54,54,54, 0.2), rgba(0,0,0,0));
    background-position: left center, right center, left center, right center;
    background-repeat: no-repeat;
    background-size: 100px 100%, 100px 100%, 40px 100%, 40px 100%;
    background-attachment: local, local, scroll, scroll;
  }
}
.horizontal-scroll-shadows {
  background:
    linear-gradient(to right, #ffffff 33%, rgba(255,255,255, 0)),
    linear-gradient(to right, rgba(255,255,255, 0), #ffffff 66%),
    radial-gradient(farthest-side at left,  rgba(54,54,54, 0.2), rgba(0,0,0,0)),
    radial-gradient(farthest-side at right, rgba(54,54,54, 0.2), rgba(0,0,0,0));
  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-size: 100px 100%, 100px 100%, 40px 100%, 40px 100%;
  background-attachment: local, local, scroll, scroll;
}
.no-data {
  display:flex;
  height:calc(100% - 41px);
  min-height:200px;
  // justify-content: stretch;
  // height: 500px;
  align-items:center;
  div {
    flex: 1;
    text-align:center
  }
}
.monospaced {
  font-family: 'Nanum Gothic Coding';
  font-size: 1.1em;
}
.code {
  background: #eee; border:1px solid #ddd; border-radius: 4px; padding: 5px;
  overflow: auto;
}
.panel {
  &.visible {
    overflow: visible;
  }
  overflow: auto;
  min-width:100%;
  max-width: fit-content;
  background: #fcfcfc77;
  border-radius: 5px;
  border: 1px solid #ddd;
  padding: 5px;
  margin-top: 10px;
  box-sizing: border-box;
  .icon {
    vertical-align: -.14em;
  }
  h4.title{
    padding:0; margin:10px 2px;
  }
  ul {
    margin: 10px 0;
    padding-inline-start: 20px;
    li:not(:last-child) {
      margin-bottom: 3px;
    }
    li::marker {
      color: #111;
      font-size: 1px;
      list-style-type: circle;
    }
  }
  table{
     table-layout: fixed;
     width: 100%;
  }
  th {
    font-weight: 600;
    text-align: left;
    white-space: nowrap;
    span {
      color:red
    }
  }
}
.submit-bar {
  margin: 20px auto 40px auto;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 0px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  white-space: nowrap;
  border: 0;
}
.file-label {
  box-sizing: border-box;
  font-size: 14px;
  line-height: 32px !important;
  text-indent: 8px;
  width: 100%;
  height: auto;
  font-family: inherit;
  padding: 0 2px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 4px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  input {
    + span {
      cursor: pointer;
    }
    &:disabled + span {
      color: #808080;
      cursor: not-allowed;
    }
  }
}
@keyframes loading {
  0% {
    backdrop-filter: blur(0px);
  }
  50% {
    backdrop-filter: blur(3px);
  }
  100% {
    backdrop-filter: blur(0px);
  }
}
