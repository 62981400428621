
nav {

  width:fit-content;
  margin: 20px auto 0px auto;
  font-size:14px;
  ul {
    margin: 0;
    padding :0;
  }
}
li {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
}
.button {
  min-width: 43px;
  color: #777;
  // color: black;
  cursor: pointer;
  margin: 1px;
  height:26px;
  border-radius: 5px;
  border:1px solid #ddd;
  background-color: #fff;
  line-height:14px;
}
ion-icon {
  vertical-align:top;
}
